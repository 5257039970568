<template>
  <div id="app">
     <router-view/>
  </div>
</template>

<script>

export default {
  components: {   
  },
  name: 'App',
  data: () => ({
    drawer: false,
    collapseOnScroll: true,
    group: null,
  }),
  methods: {

  },
  mounted() {
  },
  created() {
    document.title = this.$appName; 
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
